body {
    margin: 0;
}

@font-face {
    font-family: "BoxC Diatype";
    src: local("BoxCDiatype-Regular"), url(./fonts/BoxC-Diatype/BoxCDiatype-Regular.woff)format('woff');
}

@font-face {
    font-family: "BoxC Diatype Mono";
    src: local("BoxCDiatypeMono-Regular"), url(./fonts/BoxC-Diatype-Mono/BoxCDiatypeMono-Regular.woff)format('woff');
}

@font-face {
    font-family: "BoxC Gravity Condensed";
    src: local("BoxCGravity-Condensed"), url(./fonts/BoxC-Gravity/BoxCGravity-Condensed.woff)format('woff');
}